<template>
  <div id="main-wrapper">
    <!-- <Container/> -->
    <div class="position-relative">
      <section class="blockElement">
        <div class="container privacyPolicy">
          <h1 class="mb-4">{{contentlist.privacy_policy_content1_heading}}</h1>

          <p v-if="entityName == 'apec'">{{contentlist.privacy_policy_content1}}
            <router-link :to="entityPath+'/'" class="secondarycolor">{{contentlist.capital_wallet_content}}</router-link> {{contentlist.privacy_policy_content2}}
            <router-link :to="entityPath+'/terms-of-use'" class="secondarycolor">{{contentlist.privacy_policy_content2_link2}}</router-link> {{contentlist.privacy_policy_content3}}
          </p>

          <p v-else> {{contentlist.privacy_policy_content2_copy}}
            <router-link :to="entityPath+'/terms-of-use'" class="secondarycolor">{{contentlist.privacy_policy_content2_link2}}</router-link> {{contentlist.privacy_policy_content3}}
          </p>


          <h5>{{contentlist.privacy_policy_content4}}</h5>
          <p>{{contentlist.privacy_policy_content5}}</p>
          <ul>
            <li>{{contentlist.privacy_policy_content5_list1}}</li>
            <li>{{contentlist.privacy_policy_content5_list2}}</li>
            <li>{{contentlist.privacy_policy_content5_list3}}</li>
            <li>{{contentlist.privacy_policy_content5_list4}}</li>
            <li>{{contentlist.privacy_policy_content5_list5}}</li>
            <li>{{contentlist.privacy_policy_content5_list6}}</li>
          </ul>
          <p>{{contentlist.privacy_policy_content6}}</p>
          <p>{{contentlist.privacy_policy_content7}}</p>
          <p>{{contentlist.privacy_policy_content8}}</p>
          <h5>{{contentlist.privacy_policy_content9}}</h5>
          <p>{{contentlist.privacy_policy_content10}}</p>
          <ul>
            <li>{{contentlist.privacy_policy_content10_list1}}</li>
            <li>{{contentlist.privacy_policy_content10_list2}}</li>
            <li>{{contentlist.privacy_policy_content10_list3}}</li>
            <li>{{contentlist.privacy_policy_content10_list4}}</li>
            <li>{{contentlist.privacy_policy_content10_list5}}</li>
          </ul>
          <h5>{{contentlist.privacy_policy_content11}}</h5>
          <p>{{contentlist.privacy_policy_content12}}</p>
          <ul>
            <li>{{contentlist.privacy_policy_content12_list1}}</li>
            <li>{{contentlist.privacy_policy_content12_list2}}</li>
            <li>{{contentlist.privacy_policy_content12_list3}}</li>
            <li>{{contentlist.privacy_policy_content12_list4}}</li>
          </ul>
          <p>{{contentlist.privacy_policy_content13}}</p>
          <p>{{contentlist.privacy_policy_content14}}</p>
          <p>{{contentlist.privacy_policy_content15}}</p>
          <h5>{{contentlist.privacy_policy_content16}}</h5>
          <p>{{contentlist.privacy_policy_content17}}</p>
          <p>{{contentlist.privacy_policy_content18}}</p>
          <p>{{contentlist.privacy_policy_content19}}</p>
          <p>{{contentlist.privacy_policy_content20}}</p>
          <p>{{contentlist.privacy_policy_content21}}</p>
          <p>{{contentlist.privacy_policy_content22}}</p>
          <h5>{{contentlist.privacy_policy_content23}}</h5>
          <p>{{contentlist.privacy_policy_content24}}</p>
          <p>{{contentlist.privacy_policy_content25}}</p>
          <h5>{{contentlist.privacy_policy_content26}}</h5>
          <p>{{contentlist.privacy_policy_content27}}</p>
          <ul>
            <li>{{contentlist.privacy_policy_content27_list1}}</li>
            <li>{{contentlist.privacy_policy_content27_list2}}</li>
            <li>{{contentlist.privacy_policy_content27_list3}}</li>
            <li>{{contentlist.privacy_policy_content27_list4}}</li>
            <li>{{contentlist.privacy_policy_content27_list5}}</li>
            <li>{{contentlist.privacy_policy_content27_list6}}</li>
          </ul>
          <p>{{contentlist.privacy_policy_content28}}</p>
          <h5>{{contentlist.privacy_policy_content29}}</h5>
          <p>{{contentlist.privacy_policy_content30}}</p>
          <p>{{contentlist.privacy_policy_content31}}</p>
          <h5>{{contentlist.privacy_policy_content32}}</h5>
          <p>{{contentlist.privacy_policy_content33}}</p>
          <h5>{{contentlist.privacy_policy_content34}}</h5>
          <p>{{contentlist.privacy_policy_content35}} <a href="mailto:contact@capitalwallet.com" class="secondarycolor">{{contentlist.contact_email_content}}</a>
          </p>
          <h5>{{contentlist.privacy_policy_content36}}</h5>
          <p>{{contentlist.privacy_policy_content37}}</p>
          <h2 class="mb-3">{{contentlist.privacy_policy_content38}}</h2>
          <h5>{{contentlist.privacy_policy_content39}}</h5>
          <p>{{contentlist.privacy_policy_content40}}</p>
          <p>
            <strong>{{contentlist.privacy_policy_content41}}</strong> {{contentlist.privacy_policy_content42}}</p>
          <p>
            <strong>{{contentlist.privacy_policy_content43}}</strong> {{contentlist.privacy_policy_content44}} <strong>{{contentlist.privacy_policy_content45}}</strong>{{contentlist.privacy_policy_content46}}
          </p>
          <p>
            <strong>{{contentlist.privacy_policy_content47}}</strong> {{contentlist.privacy_policy_content48}}
          </p>
          <p>
              <strong>{{contentlist.privacy_policy_content49}}</strong> {{contentlist.privacy_policy_content50}}
            </p>
          <p>
            <strong>{{contentlist.privacy_policy_content51}}</strong> {{contentlist.privacy_policy_content52}}
          </p>
          <p>
            <strong>{{contentlist.privacy_policy_content53}}</strong> {{contentlist.privacy_policy_content54}}
          </p>
          <p>
            <strong>{{contentlist.privacy_policy_content55}}</strong> {{contentlist.privacy_policy_content56}}
          </p>
          <ul>
            <li>{{contentlist.privacy_policy_content57}}</li>
            <li>{{contentlist.privacy_policy_content58}}</li>
            <li>{{contentlist.privacy_policy_content59}}</li>
            <li>{{contentlist.privacy_policy_content60}}</li>
          </ul>
          <p>
            <i>{{contentlist.privacy_policy_content60_a}}</i>
          </p>
          <p>
            <strong>{{contentlist.privacy_policy_content61}}</strong> {{contentlist.privacy_policy_content62}} <router-link :to="entityPath+'/'" class="secondarycolor">{{contentlist.privacy_policy_content62_link}}</router-link> {{contentlist.privacy_policy_content63}}
          </p>
          <p>
            <strong>{{contentlist.privacy_policy_content64}}</strong> {{contentlist.privacy_policy_content65}} 
            <a :href="(entityName != '') ? static_vars.merchantPortalLinks[entityName].login : ''" class="secondarycolor" >{{(entityName != '') ? static_vars.merchantPortalLinks[entityName].login : ''}}</a> {{contentlist.privacy_policy_content66}}
          </p>   
        </div>
      </section>
    </div>
    <!-- <Footer /> -->
    <section class="blockElement primarybg" data-aos="fade-up" data-aos-duration="1800">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center primarybgSection">
            <div class="criptoIcon">
              <span class="left-tp one">
                <img src="/assets/images/onHover-2.webp" alt="Icon" title="Crypto 1" width="77" height="90" class="img-fluid">
              </span>
              <span class="left-tp tow">
                <img src="/assets/images/onHover-1.webp" alt="Icon" title="Crypto 2" width="98" height="98" class="img-fluid">
              </span>
              <span class="left-tp three">
                <img src="/assets/images/onHover-3.webp" alt="Icon" title="Crypto 3" width="56" height="89" class="img-fluid">
              </span>
              <span class="left-tp four">
                <img src="/assets/images/onHover-4.webp" alt="Icon" title="Crypto 4" width="87" height="86" class="img-fluid">
              </span>
            </div>
            <div class="content position-relative">
              <h2 class="whitecolor">{{contentlist.get_started_content}}</h2>
              <p class="whitecolor regular mb-0">{{contentlist.cta_three_content2}}</p>
              <a :href="(entityName != '') ? static_vars.merchantPortalLinks[entityName].register : ''" class="whiteBtn medium mt-4">{{contentlist.register_now_content}}</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
  import {
    commonAllmixins
  } from '@/plugins/commonAll' //anamica
  export default ({
    mixins: [commonAllmixins], //anamica
    data() {
      return {
        tab: 1,
      };
    },
    components: { },
    created() {
      console.log('entityName===> ', this.entityName)
    }
  });
</script>